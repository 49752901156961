/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-28 17:07:50
 * @LastEditors: yzr
 * @Description: 分利消费者页面
 */
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Modal from '@/found/components/modal';
import Form from './form';
import addBlacklist from './form/addBlacklist.js';

export default {
  name: 'ConsumerList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/consumerReport/findByConditions',
      requestType: 'GET',
    };
  },
  components: {
    Modal,
    Form,
    addBlacklist,
  },
  mounted() {
    this.getList();
    this.getPath();
  },
  methods: {
    modalClick({ val, row }) {
      // 点击编辑按钮传递数据到表单
      this.formConfig = {
        ...this.formConfig,
        code: val.code,
      };
      // 打开分利消费者详情页面
      if (val.code === 'particulars') {
        this.modalConfig.title = '分利消费者详情';
        // this.formName = 'particulars';
        this.formConfig = row;
        this.formConfig.code = 'view';
        this.formConfig.buttonCode = 'particulars';
        this.openFull();
      }
      // 查看
      if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = { ...row, btnCode: 'particulars', code: 'particulars' };
        this.openFull();
      }
      // 打开消费者黑名单页面
      if (val.code === 'check-blacklist') {
        this.modalConfig.title = '查看黑名单';
        this.formName = 'blacklist';
        // this.openFull();
        this.$router.push('/cps_web/participant/blacklist');
      }
      // 点击加入黑名单按钮
      if (val.code === 'add-blacklist') {
        this.modalConfig.title = '加入黑名单';
        this.formName = 'addBlacklist';
        this.formConfig.adds = this.selection;
        this.modalConfig.height = '400px';
        this.modalConfig.width = '600px';
        if (this.selection.length === 0) {
          this.$message('请选择至少一条数据！');
          console.log(this.selection);
        } else {
          this.openModal();
        }
      } else if (val.code === 'span-blacklist') {
        this.modalConfig.title = '加入黑名单';
        this.formName = 'addBlacklist';
        this.formConfig.adds = [row.id];
        this.modalConfig.height = '400px';
        this.modalConfig.width = '600px';
        this.openModal();
      }
      // 移除黑名单
      if (val.code === 'del-blacklist') {
        this.selection.push(row.id);
        const urls = '/cps/v1/consumer/consumer/disable';
        const text = '移出黑名单';
        request.patch(urls, [row.id]).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: `${test}失败`,
            });
          }
        });
      }
    },
    // 切换加黑名单、移除黑名单按钮
    clickVisible({ val, row }) {
      if (row.blacklistStatus === '009') {
        if (val.name === '添加黑名单') {
          return false;
        }
      }
      if (row.blacklistStatus === '003') {
        if (val.name === '移出黑名单') {
          return false;
        }
      }
      return true;
    },
    // 打开详情页面
    getPath() {
      if (this.$route.params.name === 'consumer') {
        console.log(this.$route.params);
        if (Object.keys(this.$route.params).length !== 0) {
          this.formName = 'Form';
          this.formConfig = {
            id: this.$route.params.id,
          };
          this.openFull();
        }
      }
    },
  },
  watch: {
    $route: 'getPath',
  },
  created() {
    // 重写获取配置信息
    this.getConfigList('consumer-list');
  },
};
