/*
 * @Author: 冯杰
 * @Date: 2021-08-18 16:10:48
 * @LastEditTime: 2021-09-06 16:28:26
 * @FileName: 分利消费者/新增页面
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      formFunctionCode: 'addBlackListForm',
    };
  },
  props: {
    adds: Array,
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        if (formData.blacklistReason === null) {
          formData.blacklistReason = '';
        }
        const url = `/cps/v1/consumer/consumer/enable?ids=${this.formConfig.adds}&blacklistReason=${formData.blacklistReason}`;

        request.patch(url).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
