/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-10-09 10:19:16
 * @LastEditors: yzr
 * @Description: 分利明细/分利活动列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  name: 'DealerStatisical',
  extends: TablePage,
  props: {
    consumerCode: String,
  },
  data() {
    return {
      requestUrl: '/cps/v1/web/participatorReward/findByConditions',
      requestType: 'GET',
      params: {
        code: this.consumerCode || '',
      },
    };
  },
  components: {
    Modal,
  },

  methods: {},
  created() {
    // 重写获取配置信息
    this.getConfigList('activity');
  },
};
