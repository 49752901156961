<!--
 * @Author: 冯杰
 * @Date: 2021-07-29 17:42:17
 * @LastEditTime: 2021-09-28 17:06:47
 * @LastEditors: yzr
 * @Description: 公共文件navbar
-->
<template>
  <div class="particulars-continaer">
    <!-- 分利终端详情 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="deatil">
      </el-tab-pane>
      <el-tab-pane label="分利明细" name="statistics">
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'deatil',
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(event);
      this.activeName = tab.name;
      this.$emit('tabName', this.activeName);
    },
  },
};
</script>

<style lang="less" scoped>
// .particulars-continaer {
//   background-color: #f0f2f5;

//   ::v-deep .el-tabs__header {
//     margin-bottom: 0;
//     .el-tabs__nav {
//       width: 100%;
//       height: 50px;
//       line-height: 50px;
//       margin: 0 50px;
//       .el-tabs__item {
//         font-size: 18px;
//         color: #00abed;
//       }
//     }
//   }
// }
</style>
