/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-10-09 10:28:18
 * @LastEditors: yzr
 * @Description: 分利明细
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import StatisicalFormTable from './statisical-form-table.js';

formCreate.component('StatisicalFormTable', StatisicalFormTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'consumer-statistics',
      fields: ['createTime', 'cumulativeAmount', 'cumulativeIntegral', 'remainderIntegral', 'scanCount'],
    };
  },
  props: {
    rowData: Object,
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'n') {
        item.props = {
          ...item.props,
          consumerCode: this.rowData.consumerCode,
        };
      }
      return item;
    },
    formComplete() {
      this.getDetails();
      this.disabled(true, this.fields);
      console.log(this.rowData);
    },
    getDetails() {
      // request
      //   .get('/cps/v1/web/participatorReport/findByParticipatorCodeAndType', {
      //     participatorCode: this.rowData.consumerCode,
      //     participatorType: 1,
      //   })
      //   .then((res) => {
      //     if (!res.success) return;
      //     const obj = res.result;
      //     console.log(res.result);
      //     this.setValue({
      //       ...obj,
      //     });
      //   });

      request.get('/cps/v1/web/consumerReport/findDetailsByConsumerCode', { consumerCode: this.rowData.consumerCode }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.setValue({
          ...res.result,
        });
      });
    },
    submit() {},
  },
};
