/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-11-05 18:16:00
 * @LastEditors: Please set LastEditors
 * @Description: 分利消费者详情页面
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import ConsumerDetails from './component/consumer-details.vue';
import DetailForm from './component/detail/detail-form.js';
import StatisicalForm from './component/statisical/statistical-form.js';

formCreate.component('ConsumerDetails', ConsumerDetails);
formCreate.component('DetailForm', DetailForm);
formCreate.component('StatisicalForm', StatisicalForm);

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false, // 提交按钮
        close: true, // 关闭按钮
        submitAudit: false, // 报错并提交按钮
      },
      formFunctionCode: 'consumer-form',
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'consumer-detail' || item.field === 'consumer-statistics') {
        console.log('item.field', item);
        item.props = {
          ...item.props,
          rowData: this.formConfig,
        };
      }
      return item;
    },
    formComplete() {
      this.hiddenFields(true, 'consumer-statistics');
      const ConsumerForm = this.getRule('consumer-form');
      ConsumerForm.on = {
        ...ConsumerForm.on,
        tabName: (name) => {
          // 切换到‘基本信息’
          if (name === 'deatil') {
            this.hiddenFields(false, 'consumer-detail');
            this.hiddenFields(true, 'consumer-statistics');
          }
          // 切换到‘分利统计信息’
          if (name === 'statistics') {
            this.hiddenFields(false, 'consumer-statistics');
            this.hiddenFields(true, 'consumer-detail');
          }
        },
      };
    },
  },
};
