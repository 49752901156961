/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-09-14 15:30:26
 * @LastEditors: Please set LastEditors
 * @Description: 基本信息/涉及终端列表
 */
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';

export default {
  name: 'DetailList',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/cps/v1/web/consumerTerminalMappingReport/findByConditions',
      requestType: 'GET',
      params: {
        consumerId: this.subdata.id,
      },
    };
  },
  components: {
    Modal,
  },
  props: {
    subdata: Object,
  },
  methods: {
    setColumn(col) {
      const item = col;
      if (item.field === 'checkTypeseq') {
        item.title = '序号';
        item.width = '50';
      }
      return item;
    },
  },
  async created() {
    // 重写获取配置信息
    await this.getConfigList('consumer-detail-list');
  },
};
