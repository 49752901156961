/*
 * @Author: 冯杰
 * @Date: 2021-07-22 10:33:34
 * @LastEditTime: 2021-08-18 15:40:18
 * @LastEditors: Please set LastEditors
 * @Description: 基本信息
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import ConsumerDetailList from './consumer-detail-list.js';

formCreate.component('ConsumerDetailList', ConsumerDetailList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    rowData: Object,
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'consumer-detail',
      fields: ['externalId', 'phone', 'nickname', 'createTime'],
    };
  },
  mounted() {},
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'ConsumerDetailList') {
        console.log('ConsumerDetailList', item);
        // this.formConfig = this.rowData;
        item.props = {
          ...item.props,
          subdata: this.rowData,
        };
      }
      return item;
    },
    formComplete() {
      //
      this.getSelectOptions();
      console.log('detail-form', this.rowData.id);
      // 禁用字段
      this.disabled(true, this.fields);
    },
    getSelectOptions() {
      request.get(`/cps/v1/consumer/consumer/${this.rowData.id}`).then((res) => {
        if (!res.success) return;
        this.setValue({
          ...res.result,
        });
      });
    },
  },
};
